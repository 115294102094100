import firebase from "gatsby-plugin-firebase"

export const CpiService = {
  createIns,
  getAllIns,
  deleteIns,
  getIns,
  updateIns,
  createProv,
  // getAllProv,
  deleteProv,
  updateProv,
  createMedia,
  updateMedia,
  // deleteMedia,
  createReport,
  updateReport,
  deleteReport,

  getProtocol,
}

async function createIns(data) {
  data["createTime"] = firebase.firestore.FieldValue.serverTimestamp()
  return await firebase
    .firestore()
    .collection("cpi")
    .add(data)
    .then(async docRef => {
      console.log("Document successfully written!")
      return docRef.id
    })
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}

async function getAllIns(uid) {
  let data = []
  await firebase
    .firestore()
    .collection("cpi")
    .where("uid", "==", uid)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data())
        var ins = doc.data()
        ins.key = doc.id
        data.push(ins)
      })
    })
    .catch(error => {
      console.error("Error getting document: ", error)
    })

  return data
}

async function deleteIns(insId) {
  await firebase
    .firestore()
    .doc("/cpi/" + insId)
    .delete()
}

async function getIns(insId) {
  if (insId) {
    const data = await (
      await firebase.firestore().collection("cpi").doc(insId).get()
    ).data()
    return data
  } else {
    console.error("No CPI Found")
  }
}

async function updateIns(insId, data) {
  data["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp()
  await firebase
    .firestore()
    .collection("cpi")
    .doc(insId)
    .update(data)
    .then(console.log("Document successfully updated!"))
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}

async function createProv(insId, data) {
  data["createTime"] = firebase.firestore.FieldValue.serverTimestamp()
  return await firebase
    .firestore()
    .collection("cpi/" + insId + "/provenance/")
    .add(data)
    .then(async docRef => {
      console.log("Document successfully written!")
      return docRef.id
    })
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}

// async function getAllProv(insId) {
//   let data = []
//   await firebase
//     .firestore()
//     .collection("cpi/" + insId + "/provenance/")
//     .get()
//     .then(querySnapshot => {
//       querySnapshot.forEach(doc => {
//         // doc.data() is never undefined for query doc snapshots
//         // console.log(doc.id, " => ", doc.data())
//         var prov = doc.data()
//         prov.key = doc.id
//         data.push(prov)
//       })
//     })
//     .catch(error => {
//       console.error("Error getting document: ", error)
//     })

//   return data
// }

async function deleteProv(insId, provId) {
  await firebase
    .firestore()
    .doc("/cpi/" + insId + "/provenance/" + provId)
    .delete()
}

async function updateProv(insId, provId, data) {
  data["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp()
  await firebase
    .firestore()
    .doc("/cpi/" + insId + "/provenance/" + provId)
    .update(data)
    .then(console.log("Document successfully updated!"))
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}

/// Instrument Media Service
async function createMedia(insId, data) {
  data["createTime"] = firebase.firestore.FieldValue.serverTimestamp()
  return await firebase
    .firestore()
    .collection("cpi/" + insId + "/media/")
    .add(data)
    .then(async docRef => {
      console.log("Document successfully written!")
      return docRef.id
    })
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}

async function updateMedia(insId, mediaId, data) {
  data["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp()
  await firebase
    .firestore()
    .doc("/cpi/" + insId + "/media/" + mediaId)
    .update(data)
    .then(console.log("Document successfully updated!"))
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}

async function getProtocol() {
  const data = await (
    await firebase
      .firestore()
      .collection("protocol")
      .doc("iTJWckCORHyZPkyurW5u")
      .get()
  ).data()
  return data
}

// async function deleteMedia(insId, mediaId) {
//   await firebase
//     .firestore()
//     .doc("/cpi/" + insId + "/media/" + mediaId)
//     .delete()
// }

/// Instrument alphaReport Service
async function createReport(insId, data) {
  data["createTime"] = firebase.firestore.FieldValue.serverTimestamp()
  return await firebase
    .firestore()
    .collection("cpi/" + insId + "/alphaReport/")
    .add(data)
    .then(async docRef => {
      console.log("Document successfully written!")
      return docRef.id
    })
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}

async function deleteReport(insId, reportId) {
  await firebase
    .firestore()
    .doc("/cpi/" + insId + "/alphaReport/" + reportId)
    .delete()
}

async function updateReport(insId, reportId, data) {
  data["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp()
  await firebase
    .firestore()
    .doc("/cpi/" + insId + "/alphaReport/" + reportId)
    .update(data)
    .then(console.log("Document successfully updated!"))
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}
